import * as React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import "../assets/css/news.css"
import "animate.css"

const News = ({ data }) => {
  const news = data.allContentfulNews.edges
  const { image } = data

  return (
    <Layout>
      <div className="newsHeader">News & Aktuelles</div>

      <div className="newsImage animate__animated animate__rotateIn animate__slow">
        <GatsbyImage
          image={getImage(image)}
          title={image.title}
          alt="Computertomographie"
        />
      </div>

      {news.map(({ node }) => (
        <div className="newsPostWrapper">
          <div className="newsPostContainer">
            <div className="newsTitel">{node.title}</div>

            <div
              className="newsContent"
              dangerouslySetInnerHTML={{
                __html: node.content.childMarkdownRemark.html,
              }}
            ></div>
            <div className="newsDate">
              {node.date[8]}
              {node.date[9]}.{node.date[5]}
              {node.date[6]}.{node.date[0]}
              {node.date[1]}
              {node.date[2]}
              {node.date[3]}
            </div>
          </div>
        </div>
      ))}
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query NewsQuery {
    allContentfulNews(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          title
          date
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    image: contentfulAsset(contentful_id: { eq: "27VahW322wRXOZqWsIiuxA" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
